<template>
	<div>
		<page-title></page-title>
		<div class="mt-1">
			<b-card title="项目信息">
				<project-edit-tab-information v-if="projectData" :project-data="projectData" />
			</b-card>
			<b-card title="管理此项目的用户">
				<project-edit-tab-account v-if="projectData" :project-data="projectData" />
			</b-card>
		</div>
	</div>
</template>

<script>
	import { getCurrentInstance, ref } from "@vue/composition-api";
	import store from "@/store";
	import ProjectEditTabAccount from "./ProjectEditTabAccount.vue";
	import ProjectEditTabInformation from "./ProjectEditTabInformation.vue";
	import PageTitle from "@/views/layout/PageTitle";

	export default {
		components: {
			PageTitle,
			ProjectEditTabAccount,
			ProjectEditTabInformation,
		},
		props: {
			id: [String, Number],
		},
		setup() {
			const projectData = ref(null);
			const instance = getCurrentInstance().proxy;

			if (instance.id) {
				store
					.dispatch("projects/fetchProject", instance.id)
					.then((response) => {
						console.log(response, "response-project");
						projectData.value = response.data;
					})
					.catch((error) => {
						if (error.response.status === 404) {
							projectData.value = undefined;
						}
					});
			}
			return {
				projectData,
			};
		},
	};
</script>

<style></style>
