var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"asigned-project","visible":_vm.isAsignedUserSidebarActive,"backdrop":"","bg-variant":"white","no-header":"","right":"","shadow":"","sidebar-class":"sidebar-lg"},on:{"change":function (val) { return _vm.$emit('update:is-asigned-user-sidebar-active', val); },"hidden":_vm.resetForm},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"UserPlusIcon","size":"16"}}),_vm._v(" 分配用户 ")],1),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('validation-provider',{attrs:{"name":"User_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"用户名称","label-for":"user_name"}},[_c('v-select',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.blankUserData.user_id),callback:function ($$v) {_vm.$set(_vm.blankUserData, "user_id", $$v)},expression:"blankUserData.user_id"}},_vm._l((_vm.userList),function(item,index){return _c('v-select-option',{key:index,attrs:{"value":item.user_id}},[_vm._v(" "+_vm._s(item.user_name)+" ")])}),1),_c('small',{ref:"errorText1",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"User Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"用户角色","label-for":"user-role"}},[_c('v-select',{model:{value:(_vm.blankUserData.role_id),callback:function ($$v) {_vm.$set(_vm.blankUserData, "role_id", $$v)},expression:"blankUserData.role_id"}},_vm._l((_vm.roles),function(item,index){return _c('v-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1),_c('small',{ref:"errorText2",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","type":"submit","variant":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t("Submit"))+" ")]),_c('b-button',{attrs:{"size":"sm","type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }